import { logoutUser } from "@/utils/logoutUser";
const postLogin = {
  data() {
    return {};
  },
  methods: {
    teamActivePage(user, team) {
      const { activation_level: team_activation_level, interests } = team;
      const { activation_level: user_activation_level, team_lead } = user;
      // team lead
      if (team_lead == 1) {
        if (team_activation_level == 0 && user_activation_level < 2) {
          this.$router.push({ name: "linkedin_profile" });
        } else if (
          team_activation_level == 0 &&
          user_activation_level >= 2 &&
          interests == null
        ) {
          this.$router.push({ name: "team_interests" });
        } else if (
          team_activation_level &&
          user_activation_level >= 2 &&
          interests == null
        ) {
          this.$router.push({ name: "team_interests" });
        } else if (
          !team_activation_level &&
          user_activation_level >= 2 &&
          interests
        ) {
          this.$router.push({ name: "dev_dashboard" });
        } else if (team_activation_level >= 1 && user_activation_level >= 2) {
          this.$router.push({ name: "dev_dashboard" });
        } else if (team_activation_level >= 1 && !user_activation_level) {
          this.$router.push({ name: "dev_dashboard" });
          // logoutUser();
          // window.location.reload();
        }
      }
      // Team member
      if (team_lead == 0) {
        if (user_activation_level < 2) {
          this.$router.push({ name: "linkedin_profile" });
        } else if (user_activation_level >= 2) {
          this.$router.push({ name: "dev_dashboard" });
        }
      }
    },
  },
};

export default postLogin;
