var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _vm._m(0),
      _c("Navbar"),
      _c(
        "main",
        {
          staticClass: "dev-main",
          staticStyle: { "background-color": "white" },
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "top_div" }),
              _c("br"),
              _c("br"),
              _c("h5", [_vm._v("WELCOME " + _vm._s(_vm.name.toUpperCase()))]),
              _c(
                "span",
                { staticStyle: { "font-size": "12px", color: "gray" } },
                [
                  _vm._v(
                    "Google email is " + _vm._s(decodeURIComponent(_vm.email))
                  ),
                ]
              ),
              _c("h6", { staticStyle: { "margin-top": "20px" } }, [
                _vm._v(
                  "\n         We are glad you are here. Tell us how you want to use Grupa\n       "
                ),
              ]),
              _c("center", [
                _vm.prevSelectedType > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "400px",
                          border: "0px solid red",
                          "margin-top": "30px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-warning",
                            attrs: { role: "alert" },
                          },
                          [
                            _vm._v(
                              "\n             Your previous selected option was\n             "
                            ),
                            _c("b", { staticClass: "badge badge-primary" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.prevSelectedType === 1
                                    ? "Enginner"
                                    : "Founder"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "client_type" }, [
                _c(
                  "div",
                  {
                    staticClass: "product_owner clickBait1",
                    on: {
                      click: function ($event) {
                        return _vm.updateUserType(2)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "box_title" }, [
                      _vm._v("I AM A FOUNDER"),
                    ]),
                    _c("div", { staticClass: "box_info" }, [
                      _vm._v(
                        "\n             I am looking for tech experts to build my product\n           "
                      ),
                    ]),
                    _vm._m(1),
                  ]
                ),
                _c("div", { staticClass: "vertical_divider" }),
                _c(
                  "div",
                  {
                    staticClass: "product_team clickBait2",
                    on: {
                      click: function ($event) {
                        return _vm.updateUserType(1)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "box_title" }, [
                      _vm._v("I AM AN EXPERT"),
                    ]),
                    _c("div", { staticClass: "box_info" }, [
                      _vm._v(
                        "\n             I am looking for exciting missions to contribute my technical\n             expertise\n           "
                      ),
                    ]),
                    _vm._m(2),
                  ]
                ),
              ]),
              _c("center", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "60%",
                      border: "0px solid red",
                      "align-self": "center",
                      "margin-top": "100px",
                    },
                  },
                  [
                    !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn grupa-blue-btn btn-lg proceedBtn",
                            staticStyle: { float: "right", display: "none" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.proceeddAction()
                              },
                            },
                          },
                          [_vm._v("\n             Proceed\n           ")]
                        )
                      : _vm._e(),
                    !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass: "btn grupa-gray-btn btn-lg proceedBtn",
                            staticStyle: {
                              float: "right",
                              display: "none",
                              "margin-right": "40px",
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelBtn()
                              },
                            },
                          },
                          [_vm._v("\n             Cancel\n           ")]
                        )
                      : _vm._e(),
                    _vm.loading
                      ? _c(
                          "div",
                          { staticStyle: { right: "0", "margin-top": "10%" } },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 30,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "unVerfiedSuccess",
          tabindex: "-1",
          "aria-labelledby": "unVerfiedSuccessLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "unVerfiedSuccessLabel" },
                },
                [_vm._v("\n             ERROR DETECTED\n           ")]
              ),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("br"),
              _c("img", {
                staticStyle: { "margin-top": "30px", width: "120px" },
                attrs: { src: "/img/cancel.png" },
              }),
              _c("br"),
              _c("br"),
              _c("p", { staticStyle: { "margin-top": "30px" } }, [
                _vm._v(
                  "\n             We detected an error while processing your details"
                ),
                _c("br"),
                _vm._v(
                  "\n             Click on the button bellow to reselect your Google account\n           "
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary lightBorder",
                  staticStyle: { margin: "10px" },
                  attrs: {
                    type: "button",
                    "data-bs-dismiss": "modal",
                    onclick: "window.history.go(-1);",
                  },
                },
                [_vm._v("\n             Resolve\n           ")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon_div" }, [
      _c("img", { attrs: { src: "/img/onboarding/idea.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon_div" }, [
      _c("img", { attrs: { src: "/img/onboarding/work.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }