<template>
  <div class="dev-onboard">
    <div
      class="modal fade"
      id="unVerfiedSuccess"
      tabindex="-1"
      aria-labelledby="unVerfiedSuccessLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="unVerfiedSuccessLabel">
              ERROR DETECTED
            </h5>
          </div>
          <div class="modal-body">
            <br />
            <img
              src="/img/cancel.png"
              style="margin-top: 30px; width: 120px"
            /><br /><br />
            <p style="margin-top: 30px">
              We detected an error while processing your details<br />
              Click on the button bellow to reselect your Google account
            </p>
          </div>
          <div class="modal-footer">
            <button
              style="margin: 10px"
              type="button"
              data-bs-dismiss="modal"
              class="btn btn-primary lightBorder"
              onclick="window.history.go(-1);"
            >
              Resolve
            </button>
          </div>
        </div>
      </div>
    </div>

    <Navbar />
    <!--  <main>
      <div style="left: 50%; right: 0; margin-top: 10%">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </main>
 -->

    <main class="dev-main" style="background-color: white">
      <div class="container">
        <div class="top_div"></div>

        <br />
        <br />

        <h5>WELCOME {{ name.toUpperCase() }}</h5>
        <span style="font-size: 12px; color: gray"
          >Google email is {{ decodeURIComponent(email) }}</span
        >
        <h6 style="margin-top: 20px">
          We are glad you are here. Tell us how you want to use Grupa
        </h6>

        <center>
          <div
            v-if="prevSelectedType > 0"
            style="width: 400px; border: 0px solid red; margin-top: 30px"
          >
            <div class="alert alert-warning" role="alert">
              Your previous selected option was
              <b class="badge badge-primary">{{
                prevSelectedType === 1 ? "Enginner" : "Founder"
              }}</b>
            </div>
          </div>
        </center>
        <div class="client_type">
          <div class="product_owner clickBait1" @click="updateUserType(2)">
            <div class="box_title">I AM A FOUNDER</div>
            <div class="box_info">
              I am looking for tech experts to build my product
            </div>
            <div class="icon_div">
              <img src="/img/onboarding/idea.svg" />
            </div>
          </div>

          <div class="vertical_divider"></div>

          <div class="product_team clickBait2" @click="updateUserType(1)">
            <div class="box_title">I AM AN EXPERT</div>
            <div class="box_info">
              I am looking for exciting missions to contribute my technical
              expertise
            </div>
            <div class="icon_div">
              <img src="/img/onboarding/work.svg" />
            </div>
          </div>
        </div>

        <center>
          <div
            style="
              width: 60%;
              border: 0px solid red;
              align-self: center;
              margin-top: 100px;
            "
          >
            <button
              v-if="!loading"
              type="button"
              style="float: right; display: none"
              @click="proceeddAction()"
              class="btn grupa-blue-btn btn-lg proceedBtn"
            >
              Proceed
            </button>

            <button
              v-if="!loading"
              type="button"
              style="float: right; display: none; margin-right: 40px"
              @click="cancelBtn()"
              class="btn grupa-gray-btn btn-lg proceedBtn"
            >
              Cancel
            </button>

            <div style="right: 0; margin-top: 10%" v-if="loading">
              <v-progress-circular
                :size="30"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </center>

        <!-- <div class="login_div" @click="handleLogin">
              Have an account already?
              <span>Login</span>
            </div> -->
      </div>
    </main>
  </div>
</template>
<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import postLogin from "@/mixins/postLogin";
import Navbar from "@/components/Navbar/onboarding_navbar";
import { newCustomerCallback } from "@/api/index";
import { GoogleCallback } from "@/api/team";

//

export default {
  mixins: [postLogin, mixPanel],
  components: {
    Navbar,
  },
  data: () => ({
    isVerifyingUserDetails: false,
    name: "",
    email: "",
    google_id: "",
    user_type: 0,
    loading: false,
    isError: false,
  }),
  created() {
    this.updateOldState();
    if (this.isVerifyingUserDetails === false) {
      localStorage.removeItem("engineerRole");

      if (
        this.$route.query.name &&
        this.$route.query.email &&
        this.$route.query.google_id
      ) {
        const name = this.$route.query.name;
        const email = this.$route.query.email;
        const google_id = this.$route.query.google_id;

        /* console.log(name);
        console.log(email);
        console.log(google_id); */

        this.name = name;
        this.email = email;
        this.google_id = google_id;
      } else {
        this.isError = true;
        //alert("I want problem");
        // $("#unVerfiedSuccess").modal("show");

        //alert("Your session mismatch. Click the OK button to continue");
        //document.location.href = `${this.$Website}`;
      }
      //this.fetchUserDetails();
    }
  },
  mounted() {
    // alert("Error Detected\nClick OK to reselect your Google account");
    //window.history.go(-1);
    this.$nextTick(async () => {
      // $("#unVerfiedSuccess").modal("show");

      if (this.isError) {
        window
          .$("#unVerfiedSuccess")
          .modal({ backdrop: "static", keyboard: false })
          .modal("show");
      }

      $("body").on("click", ".clickBait1", async function () {
        $(".clickBait2").removeClass("activeCard");
        $(".proceedBtn").hide();

        if ($(this).hasClass("activeCard")) {
          //alert('Yes')
          $(this).removeClass("activeCard");
        } else {
          $(this).addClass("activeCard");
          $(".proceedBtn").show();
        }
      });

      $("body").on("click", ".clickBait2", async function () {
        $(".clickBait1").removeClass("activeCard");
        $(".proceedBtn").hide();

        if ($(this).hasClass("activeCard")) {
          //alert('Yes')
          $(this).removeClass("activeCard");
        } else {
          $(this).addClass("activeCard");
          $(".proceedBtn").show();
        }
      });
    });
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserReferralCode",
    ]),
    ...mapActions("devOnboarding", [
      "setLinkedinProfile",
      "setRegistrationToken",
    ]),
    encodeDataToURL(data) {
      return Object.keys(data)
        .map((value) => `${value}=${encodeURIComponent(data[value])}`)
        .join("&");
    },
    updateOldState() {
      if (
        JSON.parse(localStorage.getItem("vuex")).hasOwnProperty(
          "userManagement"
        )
      ) {
        if (
          JSON.parse(localStorage.getItem("vuex"))[
            "userManagement"
          ].hasOwnProperty("customerType")
        ) {
          //console.log(JSON.parse(localStorage.getItem('vuex'))['userManagement'].customerType);
          this.prevSelectedType = JSON.parse(localStorage.getItem("vuex"))[
            "userManagement"
          ].customerType;
        }
        //this.prevSelectedType = this.customerType();
      }

      //alert(this.customerType())
    },
    cancelBtn() {
      document.location.href = `${this.$Website}`;
    },

    async proceeddAction() {
      let payload = {};
      if (localStorage.getItem("vcs") !== null) {
        payload = {
          name: this.name,
          email: this.email,
          google_id: this.google_id,
          user_type: this.user_type,
          vcs: JSON.parse(localStorage.getItem("vcs")).id,
        };
      } else {
        payload = {
          name: this.name,
          email: this.email,
          google_id: this.google_id,
          user_type: this.user_type,
        };
      }
      /*  const payload = {
        name: this.name,
        email: this.email,
        google_id: this.google_id,
        user_type: this.user_type,
      }; */

      this.loading = true;

      console.log(this.encodeDataToURL(payload));

      const data = this.encodeDataToURL(payload);

      // await Axios.get(this.$baseUrl + `/auth/google/callback/?${data}`)
      if (this.name == "" || this.email == "") {
        window
          .$("#unVerfiedSuccess")
          .modal({ backdrop: "static", keyboard: false })
          .modal("show");
      } else {
        GoogleCallback(data)
          .then((user) => {
            const { state, token } = user.data.data;

            this.loading = false;

            //this.setSuccessMsg("Account Created Successfully");

            this.$router.push({
              name: "google_verification",
              query: { token: token, saved: true, user_type: state },
            });

            //
          })
          .catch((error) => {
            this.loading = false;
            this.isError = true;
            console.log(error);

            /*  alert(
              "An unexpected error have occurred\nKindly confirm your network connection and try again"
            ); */
            //document.location.href = `${this.$Website}`;
            // this.setErrorMsg("An unexpected error have occured");
          });
      }
    },
    updateUserType(type) {
      this.user_type = type;
    },
  },
  watch: {
    isError: function (newItem, oldItem) {
      if (newItem === true) {
        $("#unVerfiedSuccess").modal("show");
      } else {
        $("#unVerfiedSuccess").modal("hide");
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["referralCode", "customerType"]),
    ...mapState("devOnboarding", ["registrationToken"]),
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.grupa-gray-btn {
  background: #eef1f4 !important;
  color: #121111 !important;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  width: 100px;
  height: 50px;
  padding: 10px;
}

.activeCard {
  background: linear-gradient(
    to right,
    black 0%,
    rgb(76, 75, 91) 100%
  ) !important;
}

.activeCard .box_title {
  color: #fff;
}

.activeCard .icon_div img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(170deg)
    brightness(104%) contrast(102%);
  color: "red";
}

.top_div {
  display: flex;
}
.div_cancel {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 50%;
}
.go_back {
  width: 50%;
  justify-content: left;
  display: flex;
}
.client_type {
  margin-top: 85px;
  display: flex;
  justify-content: center;
}
.product_owner {
  border: 0.5px solid #e4e5e7;
  margin-top: 35px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 302px;
  padding: 24px;
  height: 178px;
  margin-right: 66px;
  cursor: pointer;
}
.product_team {
  margin-top: 35px;
  background: #ffffff;
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  width: 307px;
  padding: 24px;
  height: 178px;
  margin-left: 66px;
  cursor: pointer;
}
.box_title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: initial;
  line-height: 130%;
  color: #1b1e22;
}
.box_info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #c8cbd0;
  margin-top: 16px;
  text-align: initial;
}
.vertical_divider {
  border-left: 1px solid #cdcdcd;
  height: 248px;
}
.icon_div {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.go_back_span {
  color: #0055a5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
}
.login_div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  margin-top: 48px;
}
.login_div span {
  color: #0055a5;
  cursor: pointer;
}
@media (max-width: 768px) {
  .client_type {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vertical_divider {
    display: none;
  }
  .product_team {
    margin-left: 0px;
  }
  .product_owner {
    margin-right: 0px;
  }
}
</style>
